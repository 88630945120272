import React, { Component } from "react";
import SharethisComponent from "../sharethiscomponent/SharethisComponent";
class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      bg: null,
    };
    this.pStyle = {
      color: `#333333`,
      fontWeight: `bold`,
      textAlign: `left`,
    };
  }

  componentDidMount() {
    if (String(window.location.search) !== "") {
      let searchParams = String(window.location.href);
      let paramUlr;
      switch (true) {
        case searchParams.includes("?giph_url") &&
          !searchParams.includes("?bg="):
          paramUlr = searchParams.split("?giph_url=")[1];
          this.setState({ url: paramUlr });
          break;
        default:
          break;
      }
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row text-center vh-100 align-items-center">
          <div className="col">
            <div>
              {this.state.url !== null &&
              typeof this.state.url !== "undefined" ? (
                <SharethisComponent url={this.state.url} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainComponent;
