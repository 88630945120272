import React, { Component } from "react";

class Giph_Component extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      modified: null,
    };
    this.frameStyle = {
      width: 100 + "%",
      height: 400 + `px`,
      border: `none`,
    };
  }

  componentDidMount() {
    let unmodified_string = this.state.url;
    let urlArray = String(unmodified_string).split("/media/");
    let embedUrl = `${urlArray[0]}/embed/${
      String(urlArray[1]).split("/giphy.gif")[0]
    }`;
    embedUrl = String(embedUrl).replace("media.", "").trim();
    this.setState({ modified: embedUrl });
  }

  render() {
    return this.state.modified !== null ? (
      <div>
        <iframe
          className="giphy-embed row"
          style={this.frameStyle}
          title="giph_frame"
          src={this.state.modified}
        ></iframe>
        <p>Via Giphy</p>
      </div>
    ) : (
      <div>no url</div>
    );
  }
}

export default Giph_Component;
